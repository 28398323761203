@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100%;
  background: rgb(184,107,119);
  background: linear-gradient(135deg, rgba(184,107,119,1) 0%, rgba(255,232,229,1) 100%);
  overflow: hidden;
}

.poloroid-paper{position:absolute;background-color:white;width:155px;height:210px;padding:10px;box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)}.poloroid-paper .poloroid-image{max-width:100%;width:155px;height:155px;background-position:center;background-size:cover}.poloroid-paper .poloroid-text-container{padding:10px 0;text-align:center;height:35px;display:flex;justify-content:center;align-items:center}.poloroid-paper .poloroid-text-container .poloroid-text{font-family:'Rock Salt', cursive;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;line-height:1.5em}

