@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.poloroid-paper {
  position: absolute;
  background-color: white;
  width: 155px;
  height: 210px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

  .poloroid-image {
    max-width: 100%;
    width: 155px;
    height: 155px;
    background-position: center;
    background-size: cover;
  }

  .poloroid-text-container {
    padding: 10px 0;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    .poloroid-text {
      font-family: 'Rock Salt', cursive;
      user-select: none;
      line-height: 1.5em;
    }
  }
}
